import { render, staticRenderFns } from "./createPTRound.vue?vue&type=template&id=1b098614&scoped=true&"
import script from "./createPTRound.vue?vue&type=script&lang=js&"
export * from "./createPTRound.vue?vue&type=script&lang=js&"
import style0 from "./createPTRound.vue?vue&type=style&index=0&id=1b098614&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b098614",
  null
  
)

export default component.exports