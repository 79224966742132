<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Create a EID PT Round</h4>
                        <form ref="resForm" @submit.prevent="createPTRound">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >PT Session</label>
                                        <multiselect required v-model="pt_session" :options="options1" :multiple="false"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Month</label>
                                        <multiselect required v-model="month" :options="options"  :multiple="false"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Choose site</label>
                                        <multiselect required v-model="sValue" :options="options2" label="site_name" track-by="site_name" @remove="removed" :hide-selected="true" @select="selected" :close-on-select="false" :clear-on-select="true" :searchable="true" :multiple="true" >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Year</label>
                                    <date-picker
                                        required
                                        v-model="year"
                                        type="year"
                                        lang="en"
                                        placeholder="Select Year"
                                    ></date-picker>

                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Result Sample 1 </label>
                                        <multiselect required v-model="results_sample1" label="name" :options="optionResults"  :multiple="false"   >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Result Sample 2</label>
                                        <multiselect required v-model="results_sample2" label="name" :options="optionResults"  :multiple="false" >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Result Sample 3</label>
                                        <multiselect required v-model="results_sample3" label="name" :options="optionResults"  :multiple="false" >
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";
    import {axios_get, axios_post} from "../../../helpers/helper";

    export default {
        page: {
            title: "Create EID PT Round",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, Multiselect,DatePicker,PageHeader },
        data() {
            return {
                site_ids : [],
                sValue : [],
                title: "Create EID  PT Round",
                user: null,
                loader:false,
                items: [
                    {
                        text: "EID PT Round",
                        href: "/view-pt-rounds"
                    },
                    {
                        text: "Create EID PT Round",
                        active: true
                    }
                ],

                options: [
                    "January",
                    "Febuary",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                options1: [
                    "SESSION1",
                    "SESSION2",
                    "SESSION3",
                    "SESSION4",
                    "SESSION5",
                ],
                options2: [],
                value:[],
                results_sample1:"",
                results_sample2:"",
                results_sample3:"",
                pt_session:null,
                optionResults:[
                    {
                        "value": 0,
                        "name":"Undetected"
                    },
                    {
                        "value": 1,
                        "name": "Detected",
                    },
                    {
                        "value": 2,
                        "name": "Invalid",
                    },
                    {
                        "value": 3,
                        "name": "",
                    }
                ],
                optionResults2:[
                    "Detected",
                    "Undetected",
                ],
                optionResults3:[
                    "Detected",
                    "Undetected",
                ],
                choose_site:[],
                value1: null,
                value2: null,
                month: null,
                year:"",
            };

        },
        validations: {

        },
        methods: {
            createPTRound(){
                this.loader = true
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
                console.log(this.results_sample3)
                    axios_post("/pt_rounds",{
                        pt_session:this.pt_session,
                        site_ids:this.site_ids,
                        pt_type:'EID',
                        month:this.month,
                        country_id: this.user.country_id,
                        results_sample1:this.results_sample1.value,
                        results_sample2:this.results_sample2.value,
                        results_sample3:this.results_sample3.value,
                        state:'PENDING',
                        year:this.year.getFullYear().toString(),
                        reference:this.pt_session+this.month.toUpperCase()+this.year.getFullYear()
                    },(d)=>{
                        window.location.href = '/ptround/eid-ptrounds'
                        console.log(d);
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader = false
                    })

            },
            resetForm(){
                this.$refs['resForm'].reset()
            },
            selected(sValue){
                console.log(sValue)
                console.log(sValue.id)
                this.site_ids.push(sValue.id);
                console.log(this.site_ids)
            },
            removed(e){
                console.log(e)
                this.site_ids = this.site_ids.filter(el=>{
                    return el != e.site_id
                });
                console.log(this.site_ids)
            },

        },
        mounted() {
            this.user = JSON.parse(localStorage.getItem('user'))
            axios_get("/sites",{},(d)=>{
                console.log(d)
                    this.options2=d.filter(e=>{return e.region.country_id==this.user.country_id && e.site_category=='EID'})
                    //this.options2=this.siteData.map(e=>(e.site_name))
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>